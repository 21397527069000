import React from 'react'

import { Container } from './404'

const NoAccessPage = () => (
  <Container>
    <span>
      You are not allowed to access this page
    </span>
  </Container>
)

export default NoAccessPage
