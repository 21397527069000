import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  assoc, assocPath, compose, path,
} from 'ramda'
import { useRecoilState, useRecoilValue } from 'recoil'

import progressState, { permPages } from '../../states/progress-state'
import surveyDataState, { translationData } from '../../states/survey-data-state'
import validationState from '../../states/validation-state'

import {
  StatusContainer,
  Button,
  NavContainer,
  NavInfo,
  CloseButton,
  ButtonsContainer,
} from './navigation.styles'

import Nav from '../nav'

export const Navigation = ({ isLoggedIn, isSiteLoginRequired }) => {
  const [{ progress }, setProgress] = useRecoilState(progressState)
  const [isShowing, toggleProgressMsg] = useState(true)
  const { locale, contentJson: { filteredPages = [] } = {} } = useRecoilValue(surveyDataState)
  const { data: translations } = useRecoilValue(translationData(locale))
  const mainPages = [filteredPages.find((p) => p.id === 'intro'), ...filteredPages.filter((p) => !permPages.includes(p.id)), filteredPages.find((p) => p.id === 'outro')]
  const [validation, setValidation] = useRecoilState(validationState)
  const { searchParams } = new URL(window.location.href)
  const dataQueryString = searchParams.get('data')

  let IS_SKIPPING_INTRO = false

  // TOKEN is usually passed as a GET query param, when the user is redirected from the proactive to the site survey
  let TOKEN = undefined

  if (dataQueryString) {
    try {
      const stringifiedData = window.atob(dataQueryString)
      if (stringifiedData) {
        const { token, skipIntro } = JSON.parse(stringifiedData)
        TOKEN = token
        IS_SKIPPING_INTRO = skipIntro === 1
      }
    } catch (e) {
      IS_SKIPPING_INTRO = false
    }
  }

  const currentIndex = useMemo(() => mainPages.findIndex(({ id: pageId }) => pageId === progress), [
    mainPages,
    progress,
  ])
  const filteredQuestions = useMemo(() => path([currentIndex, 'filteredQuestions'], mainPages), [currentIndex, mainPages])

  const prevPage = useMemo(() => (currentIndex > 0 ? mainPages[currentIndex - 1] : null), [
    currentIndex,
    mainPages,
  ])
  const nextPage = useMemo(() => (currentIndex < mainPages.length - 1 ? mainPages[currentIndex + 1] : null), [
    mainPages,
    currentIndex,
  ])

  const handlePrevious = useCallback(() => {
    if (prevPage) {
      setProgress(assoc('progress', prevPage.id))
      window.scrollTo(0, 0)
    }
  }, [prevPage, setProgress])

  const handleNext = useCallback(() => {
    const isCurrentPageValid = filteredQuestions.every(({
      id: questionId,
    }) => validation[questionId] && validation[questionId].status)

    if (isCurrentPageValid) {
      if (nextPage) {
        setProgress(compose(assoc('status', nextPage ? 1 : 2), assoc('progress', nextPage.id)))
      }
    } else {
      filteredQuestions.forEach(({ id: questionId }) => {
        if (validation[questionId] && !validation[questionId].status) {
          setValidation(assocPath([questionId, 'isVisible'], true))
        }
      })
    }
    window.scrollTo(0, 0)
  }, [nextPage, setProgress, validation, filteredQuestions, setValidation])

  const isIntro = progress === 'intro'

  const isNextPageOutro = mainPages[currentIndex + 1] && mainPages[currentIndex + 1].id === 'outro'

  console.log('progress', progress, isIntro)

  return (
    <NavContainer>
      {
        ((!isIntro && !isSiteLoginRequired) || (!isIntro && isLoggedIn && isSiteLoginRequired)) && (
          <>
            {isShowing && nextPage && (
              <NavInfo>
                {nextPage ? translations.progressmsg : translations.submitmsg}
                <CloseButton onClick={() => toggleProgressMsg(!isShowing)} />
              </NavInfo>
            )}
            {
              nextPage && (
                <StatusContainer>
                  <Nav />
                  <ButtonsContainer>
                    {(!IS_SKIPPING_INTRO || prevPage.id !== 'intro') && (
                      <Button
                        type="button"
                        disabled={!prevPage}
                        onClick={handlePrevious}
                      >
                        {translations.button.back}
                      </Button>
                    )}
                    <Button
                      type="button"
                      onClick={handleNext}
                    >
                      {isNextPageOutro ? translations.button.submit : translations.button.next}
                    </Button>
                  </ButtonsContainer>
                </StatusContainer>
              )
            }
          </>
        )
      }
    </NavContainer>
  )
}

Navigation.propTypes = {
  isSiteLoginRequired: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
}

Navigation.defaultProps = {
  isSiteLoginRequired: false,
  isLoggedIn: false,
}
