import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-overflow-scrolling: touch;
  text-align: center;

  p {
    margin: 16px 0;
    padding: 0;
    font-weight: normal;
  }
`

export const Title = styled.div`
  margin: 16px 0;
  margin-bottom: 32px;
  padding-bottom: 16px;
  font-size: 28px;
  line-height: 1.2;
  text-align: left;
  border-bottom: 1px solid #eee;

  @media (max-width: 640px) {
    font-size: 20px;
    margin: 0;
  }

  ${({ withMarginLeft }) => withMarginLeft && css`margin-left: 36px;`}

  p {
    display: inline-block;
    font-weight: bold;
  }

  ol {
    margin-left: 0;
    padding-left: 2em;
  }

  a, a:hover, a:visited, a:active {
    color: #c80000;
    text-decoration: underline;
  }

  img {
    display: block;
    max-width: 80%;
    margin: 1em auto;
  }
`

export const Description = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
`

export const Button = styled.div`
  background: #dedede;
  font-size: 14px;
  font-weight: bold;
  color: #434343;
  height: 56px;
  border: none;
  padding: 0 64px;
  cursor: pointer;
  margin: 0 8px;
  white-space: nowrap;

  &:hover {
    background: #434343;
    color: #eee;
  }
`
