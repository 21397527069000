import styled from 'styled-components'

export const Container = styled.footer`
  background: black;
  color white;
  text-align: center;
  padding: 16px 64px;
  font-size: 12px;

  p {
    margin: 0;
  }
`
