/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import Image from '../image'

import {
  StyledItem,
  Rank,
  Line,
  Container,
  ChevronUp,
  ChevronDown,
  ChevronContainer
} from './answer-sort.styles'

const Hamburger = () => (
  <Container>
    <Line />
    <Line />
    <Line />
  </Container>
)

const Item = ({
  text,
  index,
  final,
  clickable,
  move,
  length,
  imageUrl,
  altText
}) => (
  <Draggable draggableId={text} index={index}>
    {({ draggableProps, dragHandleProps, innerRef }, { isDragging }) => (
      <StyledItem
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
        isDragging={isDragging}
      >
        {
          clickable &&
            <>
              <ChevronContainer
                onClick={() => move(index, 'up')}
                disabled={index === 0}
              >
                <ChevronUp />
              </ChevronContainer>
              <ChevronContainer
                onClick={() => move(index, 'down')}
                disabled={index === length - 1}
              >
                <ChevronDown />
              </ChevronContainer>
            </>
        }
        {!clickable &&
          <>{final ? <Rank>{index + 1}</Rank> : <Hamburger />}</>
        }
        {imageUrl && <Image src={imageUrl} alt={altText} hideExpanded />}
        <p>{text}</p>
      </StyledItem>
    )}
  </Draggable>
)

Item.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
}

export default Item
