import styled, { css } from 'styled-components'

export const WithImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  ${({ imageUrl }) => !imageUrl && css`
    grid-template-columns: 1fr;
  `}

  @media (max-width: 640px) {
    grid-template-columns: 1fr;

    img {
      margin-bottom: 16px;
    }
  }
`

export const accessibilityOutline = `
  &:focus-visible {
    outline: 1px solid blue;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ imageUrl }) => !imageUrl && css`
    max-height: 60px;
  `}

  img {
    margin-top: 16px;
  }
`
