import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import { MarkdownContainer } from './answer-markdown.styles'

export const AnswerMarkdown = ({ item: { text }, loginRequired }) => {
  const handleMDLinkTarget = () => '_blank'

  return (
    <MarkdownContainer loginRequired={loginRequired}>
      <ReactMarkdown
        source={text}
        linkTarget={handleMDLinkTarget}
        escapeHtml={false}
      />
    </MarkdownContainer>
  )
}

AnswerMarkdown.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
  loginRequired: PropTypes.bool,
}

AnswerMarkdown.defaultProps = {
  loginRequired: false,
}
