import styled, { css } from 'styled-components'
import { accessibilityOutline } from '../shared/styles.css'

export const RadiosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Container = styled.div`
  font-weight: bold;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;

  ${({ imageUrl }) => imageUrl && css`
    grid-template-columns: 1fr;
  `}

  @media (max-width: 640px) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  label {
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    height: 100%;

    ${({ isValid }) => !isValid && css`
      border: 1px solid red;
    `}
  }
`

const Option = styled.input`
  background-color: white;
  appearance: none;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border: 1px solid #707b76;
  outline: none;
  cursor: pointer;

  ${accessibilityOutline}

  &:checked {
    background-color: #707b76;
    border: 2px solid white;
  }
`

export const Radio = styled(Option)`
  border-radius: 14px;
  margin: 0;
`

export const InputText = styled.input`
  appearance: none;
  width: 100%;
  border: none;
  outline: none;
  height: 100%;
  font-size: 22px;
  opacity: 0;
  position: relative;
  top: 0;
  z-index: 0;
`

export const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #ddd;
  min-height: 60px;
  width: 60px;
  border-bottom: 1px solid #ddd;
  height: 100%;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px;
  position: relative;

  background: #eee;
  width: 100%;
  min-height: 60px;
  height: 100%;

  p {
    transition: font-size 0.5s, top 0.5s;
    margin: 0 !important;
    position: relative;
    z-index: 1;
  }

  ${({ extraText }) => extraText && css`
    font-size: 16px;
    padding: 0 16px;
    background: white !important;
    color: grey;
    align-items: start;
    flex-direction: column;
    border-bottom: 1px solid #3947ad;
    height: 58px;

    p {
      top: 18px;
    }

    &:focus-within {
      p {
        top: 5px;
        font-size: 12px;
      }
    }

    ${({ isChecked }) => isChecked && `
      input {
        opacity: 1;
      }

      p {
        font-size: 12px;
        top: 5px;
      }
    `}

    ${({ isValid }) => !isValid && css`
      border-bottom: 1px solid red;
    `}
  `}

  ${({ disabled }) => disabled && css`
    background: #eee;
  `}
`
