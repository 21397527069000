import React from 'react'

const TickIcon = () => (
  <svg viewBox="0 0 342.357 342.357">
    <polygon
      points="290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 "
    />
  </svg>
)

export default TickIcon
