/* eslint-disable no-plusplus */

import {
  clone, curry, pipe, set, lensIndex,
} from 'ramda'

const swap = curry((index1, index2, list) => {
  if (index1 < 0 || index2 < 0 || index1 > list.length - 1 || index2 > list.length - 1) {
    return list
  }

  return pipe(
    set(lensIndex(index1), list[index2]),
    set(lensIndex(index2), list[index1]),
  )(list)
})

const shakeArray = (list) => {
  let newList = clone(list)
  for (let i = newList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    newList = swap(i, j, newList)
  }

  return newList
}

export default shakeArray
