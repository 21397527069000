/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useMemo, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { assocPath } from 'ramda'
import ReactMarkdown from 'react-markdown'
import { useRecoilState } from 'recoil'
import Image from '../image'

import progressState from '../../states/progress-state'
import validationState from '../../states/validation-state'
import shakeArray from '../../utils/shakeArray'

import { WithImageContainer, OptionsContainer } from '../shared/styles.css'

import {
  Container,
  Radio,
  InputText,
  RadioContainer,
  LabelContainer,
  RadiosContainer,
} from './answer-radio.styles'

import ExclamationIcon from '../shared/exclamation-icon'

export const AnswerRadio = ({
  item: {
    required,
    id,
    options,
    random,
    imageUrl,
    altText,
  },
}) => {
  const [
    {
      [id]: {
        radioStatus: isRadioValid = true,
        textStatus: isTextValid = true,
        isVisible: showErrorMsg = false,
      } = {},
    },
    setValidation,
  ] = useRecoilState(validationState)
  const input = createRef()

  const [progress, setProgress] = useRecoilState(progressState)
  const handleMDLinkTarget = () => '_blank'
  const { answers: { [id]: answer = {} } = {} } = progress

  const handleChange = useCallback(
    ({ target: { value: newValue } }) => {
      console.log('store radio')
      setValidation(assocPath([id, 'isVisible'], false))
      setProgress(
        assocPath(['answers', id], {
          value: newValue,
          text: '',
        }),
      )
    },
    [id, setProgress, setValidation],
  )

  useEffect(() => {
    if (!input.current) return
    input.current.focus()
  }, [progress])

  const sortedOptions = useMemo(() => {
    if (random) {
      return shakeArray(options)
    }
    return options
  }, [options, random])

  const handleTextChange = useCallback(
    ({ target: { value: text = '' } }) => {
      setProgress(assocPath(['answers', id, 'text'], text))
    },
    [id, setProgress],
  )

  const handleTextBlur = useCallback(() => {
    setValidation(assocPath([id, 'isVisible'], true))
  }, [setValidation, id])

  const handleTextFocus = useCallback(() => {
    setValidation(assocPath([id, 'isVisible'], false))
  }, [setValidation, id])

  const isValid = !showErrorMsg || isRadioValid

  return (
    <WithImageContainer imageUrl={imageUrl}>
      {imageUrl && <Image src={imageUrl} alt={altText} />}
      <RadiosContainer>
        <Container isValid={isValid} imageUrl={imageUrl}>
          {sortedOptions.map(
            ({
              extraText,
              extraTextRequired,
              label,
              value: optionValue,
              imageUrl: image,
            }) => {
              const isChecked = answer.value === optionValue

              return (
                <OptionsContainer key={label} imageUrl={image}>
                  <label key={optionValue} htmlFor={`q${id}.${optionValue}`}>
                    <RadioContainer>
                      <Radio
                        type="radio"
                        id={`q${id}.${optionValue}`}
                        onChange={handleChange}
                        checked={isChecked}
                        name={`q${id}.answer`}
                        value={optionValue}
                        required={required}
                        tabIndex="0"
                      />
                    </RadioContainer>
                    <LabelContainer
                      extraText={extraText}
                      isValid={!isChecked || !showErrorMsg || isTextValid}
                      disabled={!isChecked}
                      isChecked={isChecked}
                    >
                      {!isValid && !extraText && <ExclamationIcon />}
                      <ReactMarkdown
                        source={label}
                        linkTarget={handleMDLinkTarget}
                        escapeHtml={false}
                      />
                      {extraText && (
                        <>
                          <InputText
                            type="text"
                            disabled={!isChecked}
                            onChange={handleTextChange}
                            onBlur={handleTextBlur}
                            onFocus={handleTextFocus}
                            value={isChecked ? answer.text : ''}
                            isValid={!isChecked || !showErrorMsg || isTextValid}
                            required={extraTextRequired}
                            maxLength={100}
                            ref={input}
                          />
                        </>
                      )}
                    </LabelContainer>
                  </label>
                  {image && <Image src={image} alt={altText} />}
                </OptionsContainer>
              )
            }
          )}
        </Container>
      </RadiosContainer>
    </WithImageContainer>
  )
}

AnswerRadio.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    random: PropTypes.bool,
    required: PropTypes.bool,
  }).isRequired,
}
