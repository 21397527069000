import styled, { css } from 'styled-components'
import { accessibilityOutline } from '../shared/styles.css'

export const Container = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  * > div {
    box-sizing: border-box;
  }
`

const Option = styled.input`
  background-color: white;
  appearance: none;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border: 1px solid #707b76;
  outline: none;
  cursor: pointer;

  ${accessibilityOutline}

  &:checked {
    background-color: #707b76;
    border: 2px solid white;
  }
`

export const Label = styled.label`
  position: relative;
  display: flex;
  background: #ddd;
  border: 1px solid white;
  align-items: center;
  text-align: left;
  width: 100%;
  height: 100%;
  justify-content: center;
  height: 60px;
  cursor: pointer;
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`

export const Radio = styled(Option)`
  border-radius: 14px;
`

export const Title = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  align-items: center;
  color: #999;
  width: 100%;
`

export const RanksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;


  ${({ isValid }) => !isValid && css`
     > div:nth-child(2) {
      border: 1px solid red;
    }
  `}
`

export const QuestionContainer = styled.div`
  background: #eee;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  width: 80%;
  font-weight: 500;

  ${({ white }) => white && css`
    background: white;
  `}
`
