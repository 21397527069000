import styled from 'styled-components'

export const QuestionImageContainer = styled.div`
  position: relative;
  width: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
  height: fit-content;
  text-align: center;
`

export const ExpandButton = styled.button`
  padding:0;
  margin: 0;
  display: none;
  border: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  svg {
    color: white;
    filter: drop-shadow(2px 2px 4px #000);
  }

  @media (min-width: 769px) {
    display: block;
  }
`

export const QuestionImg = styled.img`
  && {
    max-width: 100%;
    height: auto;
    width: auto;
  }
`

export const ImageLightBox = styled.div`
  position: fixed;
  pointer-events: ${({ isExpanded }) => (isExpanded ? 'auto' : 'none')};
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index:999999;
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  transition: opacity 0.5s ease;
  z-index: 9999999;
`
export const ImageLightBoxBg = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
`

export const LightBoxImg = styled.img`
  && {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: 0 auto;
    pointer-events: none;
  }
`

export const LightBoxCloseButton = styled.button`
  padding:0;
  margin: 0;
  color: #ffffff;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`
