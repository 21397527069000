import styled, { css } from 'styled-components'

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const StatusContainer = styled.div`
  background: #f0f0f0;
  display: flex;
  padding: 16px 64px;

  &:before {
    content: "";
    background: linear-gradient(0deg, #ffffff, #ffffff00);
    height: 130px;
    position: absolute !important;
    top: -130px;
    left: 0;
    width: 100%;
    pointer-events: none;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    padding: 8px;
  }

  & > button {
    margin: 0 10px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 640px) {
    margin-top: 16px;
  }
`

export const Button = styled.button`
  background: #dedede;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #434343;
  height: 56px;
  border: none;
  display: inline-block;
  width: auto;
  min-width: 148px;
  padding: 0 16px;
  cursor: pointer;
  margin: 0 8px;
  white-space: nowrap;

  ${({ loginRequired }) => loginRequired && css`display: block;`}

  &:hover {
    background: #434343;
    color: #eee;
  }

  @media (max-width: 640px) {
    height: 32px;
    margin: 4px;
    width: 90%;
  }

  ${({survey}) => survey && css`
    margin: 32px auto;
    width: 20%;
    background: #434343;
    color: #dedede;
    text-transform: uppercase;

    @media (max-width: 640px) {
      width: 80%;
      height: 60px;
      margin: 32px auto;
    }
  `}
`

export const NavInfo = styled.div`
  background: white;
  border-top: 1px solid #eee;
  display: flex;
  padding: 16px;
  justify-content: center;
  color: #666;
  font-weight: bold;
  z-index: 999;
  align-items: center;

  @media (max-width: 640px) {
    font-size: 11px;
  }
`

export const CloseButton = styled.div`
  position: relative;
  left: 4px;
  width: 16px;
  height: 16px;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:before, &:after {
    position: absolute;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`
