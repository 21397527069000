/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useMemo, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { append, assocPath, clone, path, remove } from 'ramda'
import ReactMarkdown from 'react-markdown'
import { useRecoilState } from 'recoil'
import Image from '../image'

import progressState from '../../states/progress-state'
import validationState from '../../states/validation-state'
import shakeArray from '../../utils/shakeArray'

import ExclamationIcon from '../shared/exclamation-icon'
import TickIcon from '../shared/tick-icon'

import { WithImageContainer, OptionsContainer } from '../shared/styles.css'

import {
  CheckboxesContainer,
  Container,
  Checkbox,
  InputText,
  LabelContainer,
  InputContainer,
} from './answer-checkbox.styles'

export const AnswerCheckbox = ({
  item: {
    required,
    id,
    maxSelections,
    options,
    random,
    imageUrl,
    altText
  },
}) => {
  const [
    {
      [id]: {
        checkboxStatus: isCheckboxValid = true,
        textStatus: isTextValid = true,
        isVisible: showErrorMsg = false,
      } = {},
    },
    setValidation,
  ] = useRecoilState(validationState)

  const input = createRef()
  const [progress, setProgress] = useRecoilState(progressState)
  const handleMDLinkTarget = () => '_blank'
  const { answers: { [id]: value = [] } = {} } = progress

  useEffect(() => {
    if (!input.current) return
    input.current.focus()
  }, [progress])

  const handleChange = useCallback(
    (e) => {
      setValidation(assocPath([id, 'isVisible'], false))
      setProgress((currentProgress) => {
        const selections = path(['answers', id], currentProgress) || []
        const newValue = path(['target', 'value'], e)
        let answer = clone(selections)

        const existingIndex = answer.findIndex(
          (selectedOption) => selectedOption.value === newValue,
        )

        if (existingIndex > -1) {
          answer = remove(existingIndex, 1, answer)
        } else {
          answer = append({ value: newValue, text: '' }, answer)
        }

        return assocPath(
          ['answers', id],
          answer.length > 0 ? answer : undefined,
        )(currentProgress)
      })
    },
    [id, setProgress, setValidation],
  )

  const sortedOptions = useMemo(() => {
    if (random) {
      return shakeArray(options)
    }
    return options
  }, [options, random])

  const handleTextChange = useCallback(
    (optionValue) => ({ target: { value: text } }) => {
      setProgress((currentProgress) => {
        const selections = path(['answers', id], currentProgress) || []

        let answer = clone(selections)

        const index = answer.findIndex(
          (selectedOption) => selectedOption.value === optionValue
        )

        if (index > -1) {
          answer = assocPath([index, 'text'], text)(answer)
        }

        return assocPath(['answers', id], answer)(currentProgress)
      })
    },
    [id, setProgress],
  )

  const handleTextBlur = useCallback(() => {
    setValidation(assocPath([id, 'isVisible'], true))
  }, [setValidation, id])

  const handleTextFocus = useCallback(() => {
    setValidation(assocPath([id, 'isVisible'], false))
  }, [setValidation, id])

  const isValid = !showErrorMsg || isCheckboxValid

  return (
    <WithImageContainer imageUrl={imageUrl}>
      {imageUrl && <Image src={imageUrl} alt={altText} />}
      <CheckboxesContainer>
        <Container isValid={isValid} imageUrl={imageUrl}>
          {sortedOptions.map(
            ({
              extraText,
              extraTextRequired,
              label,
              value: optionValue,
              imageUrl: image,
            }) => {
              const currentItem = value.find(
                (selectedOption) => selectedOption.value === optionValue
              )
              const isChecked = !!currentItem

              return (
                <OptionsContainer imageUrl={image}>
                  <label key={optionValue} htmlFor={`q${id}.${optionValue}`}>
                    <InputContainer>
                      <Checkbox
                        type="checkbox"
                        id={`q${id}.${optionValue}`}
                        onChange={handleChange}
                        checked={isChecked}
                        name={`q${id}.answer`}
                        value={optionValue}
                        disabled={
                          maxSelections &&
                          value &&
                          value.length &&
                          !isChecked &&
                          value.length === maxSelections
                        }
                        required={required}
                        tabIndex="0"
                      />
                      {isChecked && <TickIcon />}
                    </InputContainer>
                    <LabelContainer
                      extraText={extraText}
                      isValid={!isChecked || !showErrorMsg || isTextValid}
                      disabled={!isChecked}
                      isChecked={isChecked}
                    >
                      {!isValid && !extraText && <ExclamationIcon />}
                      <ReactMarkdown
                        source={label}
                        linkTarget={handleMDLinkTarget}
                        escapeHtml={false}
                      />
                      {extraText && (
                        <InputText
                          type="text"
                          disabled={!isChecked}
                          onChange={handleTextChange(optionValue)}
                          onBlur={handleTextBlur}
                          onFocus={handleTextFocus}
                          value={(currentItem || {}).text || ''}
                          isValid={!isChecked || !showErrorMsg || isTextValid}
                          required={extraTextRequired}
                          maxLength={100}
                          ref={input}
                        />
                      )}
                    </LabelContainer>
                  </label>
                  {image && <Image src={image} alt={altText} />}
                </OptionsContainer>
              )
            },
          )}
        </Container>
      </CheckboxesContainer>
    </WithImageContainer>
  )
}

AnswerCheckbox.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    maxSelections: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    random: PropTypes.bool,
    required: PropTypes.bool,
  }).isRequired,
}
