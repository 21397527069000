import styled from 'styled-components'

export const Container = styled.div`
  fill: red;
  width: 26px;
  margin-right: 8px;
  display: flex;
  height: 26px;
  align-items: center;
  justify-content: center;
`
