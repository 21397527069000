import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import AnswerText from '../answer-text'
import AnswerCheckbox from '../answer-checkbox'
import AnswerRadio from '../answer-radio'
import AnswerSort from '../answer-sort'
import AnswerRange from '../answer-range'
import AnswerDropdown from '../answer-dropdown'
import AnswerWordselect from '../answer-wordselect'
import AnswerMarkdown from '../answer-markdown'
import AnswerImage from '../answer-image'
import AnswerRank from '../answer-rank'
import AnswerMatrix from '../answer-matrix'

import { Container, Description, Title } from './question.styles'

export const Question = ({ item, loginRequired }) => {
  const { subtitle, title, type } = item
  const handleMDLinkTarget = () => '_blank'

  return (
    <Container>
      {!!title && (
        <Title>
          <ReactMarkdown source={title} linkTarget={handleMDLinkTarget} escapeHtml={false} />
        </Title>
      )}
      {!!subtitle && (
        <Description>
          <ReactMarkdown source={subtitle} linkTarget={handleMDLinkTarget} escapeHtml={false} />
        </Description>
      )}

      { type === 'markdown' && (<AnswerMarkdown item={item} loginRequired={loginRequired} />) }
      { type === 'image' && (<AnswerImage item={item} />) }
      { type === 'text' && (<AnswerText item={item} />) }
      { type === 'checkbox' && (<AnswerCheckbox item={item} />) }
      { type === 'radio' && (<AnswerRadio item={item} />) }
      { type === 'sort' && (<AnswerSort item={item} />) }
      { type === 'range' && (<AnswerRange item={item} />) }
      { type === 'rank' && (<AnswerRank item={item} />) }
      { type === 'dropdown' && (<AnswerDropdown item={item} />) }
      { type === 'wordselect' && (<AnswerWordselect item={item} />) }
      { type === 'matrix' && (<AnswerMatrix item={item} />) }
    </Container>
  )
}

Question.propTypes = {
  item: PropTypes.shape({
    subtitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  loginRequired: PropTypes.bool,
}

Question.defaultProps = {
  loginRequired: false,
}
