import React from 'react'

import { StyledColumn, StyledList, StyledItem, Rank, Line, Container } from './answer-sort.styles'

const Hamburger = () => (
  <Container>
    <Line />
    <Line />
    <Line />
  </Container>
)

const PlaceholderColumn = ({ list, final, text, highlight }) => (
  <StyledColumn placeholder final={!final}>
    <StyledList>
      {list.map((_, index) => (
          <StyledItem
            key={index}
            highlight={index <= highlight}
          >
            {final ? <Rank>{index + 1}</Rank> : <Hamburger />}
            {text}
          </StyledItem>
      ))}
    </StyledList>
  </StyledColumn>
)

export default PlaceholderColumn
