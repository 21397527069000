import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { useRecoilState, useRecoilValue } from 'recoil'
import { assoc, compose } from 'ramda'

import progressState, { permPages } from '../../states/progress-state'
import surveyDataState, { translationData } from '../../states/survey-data-state'
import validationState from '../../states/validation-state'

import Navigation from '../navigation'
import Question from '../question'
import Footer from '../footer'
import Image from '../image'

import { Button } from '../navigation/navigation.styles'

import {
  Container,
  Header,
  Logo,
  ErrorMsg,
  Questions,
  FooterContainer,
  ButtonContainer,
} from './layout.styles'
import config from '../../config'

const {
  env: {
    REACT_APP_MEMBERSHIP_URL,
    REACT_APP_RETURN_URL,
  },
} = config

const queryHref = encodeURIComponent(window.location.href)


export const Layout = () => {
  const [{ progress }, setProgress] = useRecoilState(progressState)

  const [showFooter, toggleShowFooter] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isFetchComplete, setFetchIsComplete] = useState(false)
  const { locale, slug, contentJson: { filteredPages = [], text: surveyText, banner = {}, isSurveyClosed, isSiteLoginRequired } = {} } = useRecoilValue(surveyDataState)
  const { data: translations } = useRecoilValue(translationData(locale))
  const querySiteTag = useMemo(() => encodeURIComponent(slug), [slug])
  const queryLocale = useMemo(() => encodeURIComponent(locale), [locale])
  const { altText, imageUrl } = banner
  const validation = useRecoilValue(validationState)
  const { filteredQuestions = [] } = useMemo(() => filteredPages.find(({ id }) => id === progress) || {}, [filteredPages, progress])
  const isErrorMsgVisible = useMemo(() => filteredQuestions.some(({
    id: questionId,
  }) => validation[questionId] && !validation[questionId].status && validation[questionId].isVisible), [
    filteredQuestions,
    validation,
  ])

  useEffect(() => {
    checkScroll()
  }, [])

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const res = await fetch(`${REACT_APP_RETURN_URL}/user/sso/status`, {
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        const resJson = await res.json()
        console.log('xstatus', resJson)
        setFetchIsComplete(true)
        setIsLoggedIn(resJson && resJson.locale ? resJson.locale.length > 0 : false)
      } catch(err) {
        console.log('error:', err)
        setFetchIsComplete(true)
        setIsLoggedIn(false)
      }
    }

    checkLogin()

  }, [])

  const checkScroll = useCallback(() => {
    window.innerHeight === document.body.clientHeight
      ? toggleShowFooter(true)
      : toggleShowFooter(false)

    window.onscroll = function() {
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight
        ? toggleShowFooter(true)
        : toggleShowFooter(false)
     }
  }, [])

  const endListener = useCallback((node, done) => {
    node.addEventListener('transitionend', done, false)
  }, [])

  if (!filteredPages || !progress || !filteredQuestions.length) {
    return null
  }

  const isIntro = progress === 'intro'
  const isOutro = progress === 'outro'
  const closed = filteredPages.find(p => p.id === 'closed')
  const loginRequired = filteredPages.find(p => p.id === 'loginRequired')
  const mainPages = filteredPages.filter((p) => !permPages.includes(p.id))

  const serviceUrl = encodeURIComponent(`${REACT_APP_RETURN_URL}/user/sso?ret=${queryHref}`)

  console.log({isSiteLoginRequired, isLoggedIn, loginRequired, serviceUrl})

  return (
    <Container>
      {
        (isIntro || isOutro || isSurveyClosed || isSiteLoginRequired) &&
          <>
            {
            imageUrl
              ? <Image src={imageUrl} alt={altText} hideExpanded/>
              : <Header>
                  <Logo src="/SE_Logo_White.svg" alt="Square Enix" />
                </Header>
            }
        </>
      }
      <SwitchTransition>
        <CSSTransition
          classNames="item"
          key={progress}
          addEndListener={endListener}
          onEntered={checkScroll}
        >
          <Questions isIntroOrOutro={(isIntro || isOutro)}>
          {isErrorMsgVisible && (
              <ErrorMsg>
                {translations.errors.required}
              </ErrorMsg>
            )}
            {(isFetchComplete && (!isSurveyClosed && !isSiteLoginRequired) || (!isSurveyClosed && isSiteLoginRequired && isLoggedIn)) && filteredQuestions.map((question) => (
               <Question key={question.id} item={question} />
            ))}

            {isFetchComplete && !isSurveyClosed && isSiteLoginRequired && !isLoggedIn && <Question key={loginRequired.questions[0].id} item={loginRequired.questions[0]} loginRequired={isSiteLoginRequired} />}
            {isFetchComplete && isSurveyClosed && <Question key={closed.questions[0].id} item={closed.questions[0]} />}
            {
              ((isFetchComplete && isIntro && !isSurveyClosed && !isSiteLoginRequired) || (isIntro && !isSurveyClosed && isSiteLoginRequired && isLoggedIn)) &&
                <Button
                  type="button"
                  onClick={() => setProgress(compose(assoc('status', 1), assoc('progress', mainPages[0].id)))}
                  survey
                >
                  {surveyText['intromsg'] || 'Take the survey'}
                </Button>
            }
            {
             isFetchComplete && (isSiteLoginRequired && !isLoggedIn && !isSurveyClosed) &&
              <ButtonContainer>
                <Button
                  type="button"
                  loginRequired={isSiteLoginRequired}
                  onClick={() => window.location.href = `${REACT_APP_MEMBERSHIP_URL}/sso/login?locale=${queryLocale}&src=${querySiteTag}&service=${serviceUrl}`
                }
                  survey
                >
                  {translations.button.login}
                </Button>
                <Button
                  type="button"
                  loginRequired={isSiteLoginRequired}
                  onClick={() => window.location.href =`${REACT_APP_MEMBERSHIP_URL}/sso/login?locale=${queryLocale}&src=${querySiteTag}&typ=register&service=${serviceUrl}`
                }
                  survey
                >
                  {translations.button.register}
                </Button>
                </ButtonContainer>
            }


          </Questions>
        </CSSTransition>
      </SwitchTransition>
      <FooterContainer isIntro={isIntro}>
        <Navigation isLoggedIn={isLoggedIn} isSiteLoginRequired={isSiteLoginRequired} />
        {showFooter && <Footer />}
      </FooterContainer>
    </Container>
  )
}
