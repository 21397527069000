import styled, { css } from 'styled-components'

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 9999;

  h2 {
    margin: 0;
    padding: 0 16px;
  }

  ${({ placeholder }) => placeholder && css`
    width: 50%;
    position: absolute;
    top: 0;
    z-index: -1;
  `}

  ${({ final }) => final && css`
    right: 0;
  `}
`

export const StyledList = styled.div`
  padding: 0 16px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1px;

  @media (max-width: 640px) {
    padding: 0;
  }
`

export const StyledItem = styled.div`
  background-color: #eee;
  border: 1px solid #ccc;
  transition: background-color .8s ease-out;
  margin-bottom: 8px;
  height: 60px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #fff;
    transition: background-color .1s ease-in;
  }

  img {
    height: 46px !important;
    display: flex;
    margin-right: 8px;
  }

  p {
    padding: 16px !important;
    padding-left: 8px !important;
    margin: 0 !important;
  }

  ${({ isDragging }) => isDragging && css`
    opacity: 0.6;
  `}

  ${({ highlight }) => highlight && css`
    opacity: 0.2;
  `}

  @media (max-width: 640px) {
    &:hover {
      background-color: #eee;
    }
  }
`

export const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  ${({ height }) => height && css`
    height: ${(height * 68 + 12)}px;
  `}
`

export const Rank = styled.div`
  color: red;
  font-weight: bold;
  padding: 0 26px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 26px;
  background: #ddd;
  margin-right: 6px;
  margin-left: 6px;
  height: 46px;
  width: 80px;
  align-items: center;
  justify-content: center;
  padding: 0;
`

export const Line = styled.div`
  width: 28px;
  height: 2px;
  background-color: #999;
  margin: 2px 0;
`

const chevron = `
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;

  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    left: 4px;
    bottom: 2px;
  }
`

export const ChevronUp = styled.i`
  ${chevron}

  &::after {
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
  }
`

export const ChevronDown = styled.i`
  ${chevron}

  &::after {
    transform: rotate(45deg);
    border-bottom: 2px solid;
    border-right: 2px solid;
    bottom: 7px;
  }
`

export const ChevronContainer = styled.div`
  height: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ disabled }) => disabled && css`
    opacity: 0.2;
  `}
`

export const Button = styled.button`
  background: #eee;
  border: 1px solid #000;
  padding: 16px 32px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }

  @media (max-width: 640px) {
    margin-top: 8px;
  }
`
