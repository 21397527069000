import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  ExpandButton,
  ImageLightBox,
  ImageLightBoxBg,
  LightBoxCloseButton,
  LightBoxImg,
  QuestionImageContainer,
  QuestionImg,
} from './image.styles'

import CloseIcon from './close-icon'
import ExpandIcon from './expand-icon'

export const Image = ({ src, hideExpanded, alt }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const cdnUrl = process.browser ? window.env.REACT_APP_CDN : process.env.REACT_APP_CDN
  const imgSrc = `${cdnUrl}/assets/original${src}`

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <QuestionImageContainer>
      <QuestionImg src={imgSrc} alt={alt} />
      {
        !hideExpanded &&
          <ExpandButton onClick={toggleExpanded}>
            <ExpandIcon />
          </ExpandButton>
      }
      <ImageLightBox isExpanded={isExpanded}>
        <ImageLightBoxBg
          onClick={toggleExpanded}
          isExpanded={isExpanded}
        />
        <LightBoxImg src={imgSrc} alt={alt} />
        <LightBoxCloseButton onClick={toggleExpanded}>
          <CloseIcon />
        </LightBoxCloseButton>
      </ImageLightBox>
    </QuestionImageContainer>
  )
}

Image.defaultProps = {
  alt: '',
  hideExpanded: false,
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  hideExpanded: PropTypes.bool,
}
