import { atom, selectorFamily } from "recoil";
import config from "../config";

const project = 'DP-SURVEY-UI'

const {
  env: {
    REACT_APP_TRANSLATION_API,
  },
} = config

const surveyDataState = atom({
  key: 'surveyDataState',
  default: {},
})

export const translationData = selectorFamily({
  key: 'getTranslatio',
  get:
    (locale) => async () => {
      try {
        const transApiUrl = `${REACT_APP_TRANSLATION_API}/${project}/${locale}`
        const response = await fetch(transApiUrl, {
          method: 'GET',
        })
        const data = await response.json()

        return { data }
      } catch (error) {
        return { error }
      }
    },
})

export default surveyDataState;
