/* eslint-disable jsx-a11y/label-has-associated-control, no-plusplus */

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { assocPath, path } from 'ramda'
import { useRecoilState } from 'recoil'
import Image from '../image'

import progressState from '../../states/progress-state'
import validationState from '../../states/validation-state'
import shakeArray from '../../utils/shakeArray'

import { Select, Option, DropdownContainer } from './answer-dropdown.styles'

import { WithImageContainer } from '../shared/styles.css'

export const AnswerDropdown = ({
  item: {
    required,
    id,
    options,
    random,
    defaultText,
    imageUrl,
    altText,
    ...rest
  },
}) => {
  const [progress, setProgress] = useRecoilState(progressState)
  const [{
    [id]: {
      status: isValid = true,
      isVisible: showErrorMsg = false,
    } = {},
  }] = useRecoilState(validationState)
  const value = path(['answers', id, 'value'], progress)

  const handleChange = useCallback(({ target: { value: newValue } }) => {
    if (newValue === defaultText) {
      setProgress(assocPath(['answers', id, 'value'], ''))
    } else {
      setProgress(assocPath(['answers', id, 'value'], newValue))
    }
  }, [id, setProgress])

  const sortedOptions = useMemo(() => {
    if (random) {
      return shakeArray(options)
    }
    return options
  }, [options, random])

  return (
    <WithImageContainer imageUrl={imageUrl}>
      {imageUrl && <Image src={imageUrl} alt={altText} />}
      <DropdownContainer>
        <Select onChange={handleChange} value={value} required={required} isValid={!showErrorMsg || isValid}>
          <Option value={null}>{defaultText}</Option>
          {sortedOptions.map(({ label, value: optionValue }) => (
            <Option value={optionValue} key={optionValue}>{label}</Option>
          ))}
        </Select>
      </DropdownContainer>
    </WithImageContainer>
  )
}

AnswerDropdown.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    random: PropTypes.bool,
    required: PropTypes.bool,
  }).isRequired,
}
