import React, {
  Suspense,
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Cookies from 'js-cookie'
import {
  assoc, compose, equals, path,
} from 'ramda'
import { Helmet } from 'react-helmet'
import md5 from 'md5'
import { ThemeProvider } from 'styled-components'

import surveyDataState from './states/survey-data-state'
import progressState from './states/progress-state'
import usePrevious from './hooks/use-previous'
import Layout from './components/layout'

import config from './config'
import { GlobalStyle } from './App.styles'
import Page404 from './404'

import NoAccessPage from './NoAccessPage'

const {
  env: {
    REACT_APP_COOKIE_USER_DATA,
    REACT_APP_FEEDBACK_API,
    REACT_APP_GRAPHQL_URI,
    REACT_APP_MEMBERSHIP_URL,
    REACT_APP_SITE_INDEX,
    REACT_APP_CDN,
    REACT_APP_RETURN_URL,
    REACT_APP_HEADERBAR,
    REACT_APP_AUTH_TOKEN,
  },
} = config

const { pathname, searchParams } = new URL(window.location.href)
const [, LOCALE = 'en', TAG = ''] = pathname.split('/')
const IS_PREVIEW_MODE = searchParams.get('preview') === 'true'
const dataQueryString = searchParams.get('data')

let IS_SKIPPING_INTRO = false

// TOKEN is usually passed as a GET query param, when the user is redirected from the proactive to the site survey
let TOKEN = undefined

if (dataQueryString) {
  try {
    const stringifiedData = window.atob(dataQueryString)
    if (stringifiedData) {
      const { token, skipIntro } = JSON.parse(stringifiedData)
      TOKEN = token
      IS_SKIPPING_INTRO = skipIntro === 1
    }
  } catch (e) {
    IS_SKIPPING_INTRO = false
  }
}

const COOKIE_KEY = `SURVEY_${(md5(TAG)).substring(0, 8)}`

// set the initial user id as the token or the cookie if present
const USER_ID = TOKEN ?? Cookies.get(COOKIE_KEY)

window.resetProgress = () => Cookies.remove(COOKIE_KEY)

const App = () => {
  const [, setSurveyData] = useRecoilState(surveyDataState)
  const [progress, setProgress] = useRecoilState(progressState)
  const [isFetchComplete, setFetchIsComplete] = useState(false)
  const { contentJson: { isSiteLoginRequired } = {} } = useRecoilValue(surveyDataState)
  const prevProgress = usePrevious(progress)
  const [userid, setUserId] = useState(USER_ID || null)
  const [show404, updateShow404] = useState(false)

  const quakCookie = Cookies.get(REACT_APP_AUTH_TOKEN)
  let quak
  if (quakCookie) {
    const decoded = Buffer.from(quakCookie, 'base64').toString('utf-8');
    quak = JSON.parse(decoded).quak
  }

  const preview = IS_PREVIEW_MODE

  const semid = useMemo(() => {
    try {
      const cookieUserData = Cookies.get(REACT_APP_COOKIE_USER_DATA)
      const { id } = JSON.parse(cookieUserData)
      return id.toString()
    } catch (e) {
      return '0'
    }
  }, [])

  /**
   * Retrieves the correct survey version from remote
   * @type {(function(): Promise<void>)|*}
   */
  const fetchData = useCallback(async () => {
    /**
    * Retrieves the last version of the given survey, defined by TAG as a slug
    * @return {Promise<undefined>}
    */
    const getLatestSurvey = async function(preview) {
      const res = await fetch(
      `${REACT_APP_GRAPHQL_URI}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `bearer quak ${quak}`,
        },
        body: JSON.stringify(
        {
          query: `query ($locale: String!, $type: String!, $slug: String!, $onlyPublished: Boolean) {
            content(locale: $locale, type: $type, slug: $slug, onlyPublished: $onlyPublished) {
              contentJson
              id
              locale
              slug
            }
          }`,
          variables: {
            locale: LOCALE,
            type: 'survey:site',
            slug: TAG,
            onlyPublished: !preview,
          },
        }),
      })

      const { data: { content = undefined } } = await res.json()
      return content
    }

    /**
     * Retrieves content data belonging to a specific version of the given survey
     * @param id id of the content representing the survey
     * @param locale locale of the content
     * @return {Promise<undefined>}
     */
    const getSurveyById = async function(id, locale) {
      const res = await fetch(
        `${REACT_APP_GRAPHQL_URI}`,
        {
          method: 'POST',
          headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `bearer quak ${quak}`,
        },
        body: JSON.stringify(
          {
            query: `query ($locale: String!, $type: String!, $id: String!) {
              surveyById(id: $id, locale: $locale, type: $type) {
                contentJson
                id
                locale
                slug
              }
            }`,
            variables: {
              locale: locale,
              type: 'survey:site',
              id: id.toString(),
            },
          }),
        })

        const { data: { surveyById = undefined } } = await res.json()
        return surveyById
    }

    /**
     * initNewSurvey creates a new survey record and initializes and sets as current an empty progress for the survey
     * @param preview true if the new survey needs to be initialized in preview mode. In this case survey records are not being
     * submitted to the remote API
     * @return {Promise<void>}
     */
    const initNewSurvey = async function(preview) {
      // get the latest version of the given survey
      const survey = await getLatestSurvey(preview)

      if (!survey) {
        updateShow404(true)
        return
      }

      const progress = {
        id: Number(survey?.id),
        slug: TAG,
        answers: {},
        locale: LOCALE,
        status: 0,
        semid: semid.toString(),
        progress: path(['contentJson', 'pages', IS_SKIPPING_INTRO ? 1 : 0, 'id'])(survey),
      }

      // if not in preview mode create the new record for the survey
      // if (!(quak && IS_PREVIEW_MODE)) {

      const { contentJson } = survey
      const { isSurveyClosed } = contentJson

      if (!preview && !isSurveyClosed) {
        const url = userid ? `${REACT_APP_FEEDBACK_API}/survey/${userid}` : `${REACT_APP_FEEDBACK_API}/survey`
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...progress,
            answers: '{}',
          }),
        })

        if (!res.ok) {
          throw new Error(`could not create new survey. Server responded with ${res.status}: ${res.statusText}`)
        }

        // set the cookie as the survey record has now been created
        if (!userid) {
          const { token } = await res.json()
          Cookies.set(COOKIE_KEY, token, { expires: 14 })
          setUserId(token)
        }
      }

      setSurveyData(survey)
      setProgress(progress)
    }

    try {
      // there is no token or cookie or indication in general that this is an existing survey.
      // in this case initialize a new survey and use that as current
      // if in preview mode always initialize a new survey
      if (!userid || preview) {
        await initNewSurvey(preview)
      } else {
        // there is a
        const response = await fetch(`${REACT_APP_FEEDBACK_API}/survey/${userid}`)
        // got a 2xx response from the API. the survey does exist and has a progress
        // retrieve the content at the given version and populate the progress state
        if (response.ok) {
          const result = await response.json()
          const progress = compose(assoc('semid', semid), assoc('status', 1))(result)
          // retrieve the survey data
          const survey = await getSurveyById(result?.id, result.locale)
          setSurveyData(survey)
          setProgress(progress)
          // set the cookie to the token value, in case the user returns to the survey at a later date
          if (!Cookies.get(COOKIE_KEY)) {
            Cookies.set(COOKIE_KEY, userid, { expires: 14 })
          }

        } else {
          // could not find the survey, create a new one
          switch (response.status) {
            // could not find the specified survey. Create a new one, initialize the progress and keep going
            case 404:
              await initNewSurvey(preview)
              break
            default:
              throw new Error(`something went wrong. Server responded with status ${response.status}: ${response.statusText}`)
          }
        }
      }
    } catch (e) {
      // todo: handle the exception gracefully
      console.error(e)
    }
    setFetchIsComplete(true)
  }, [userid, setProgress, semid, setSurveyData, quak])

  useEffect(() => {
    fetchData()
  }, []) // eslint-disable-line

  /**
   * sends the current progress to the remote API for updates on the survey state
   * @type {(function(): Promise<void>)|*}
   */
  const saveProgress = useCallback(async () => {
    try {
      await fetch(`${REACT_APP_FEEDBACK_API}/survey/${userid}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...progress,
          answers: JSON.stringify(progress.answers),
        }),
      })
    } catch (e) {
      console.error(e)
    } // eslint-disable-line
  }, [progress, userid])

  /**
   * issues a call to remote services to save the current progress, unless in preview mode
   */
  useEffect(() => {
    if (preview) {
      return
    }

    if (path(['answers'], prevProgress) && !equals(prevProgress, progress)) {
      saveProgress()
    }
  }, [progress, prevProgress, saveProgress, preview])

  if (!isFetchComplete) {
    return null
  }

  const showNoAccessPage = (IS_PREVIEW_MODE && (!quak || quak === 'NOTIN'))

  return (
    <ThemeProvider theme={{ palette: {} }}>
      <>
        <GlobalStyle />
        <Helmet>
          <script>
            {`
              window.ghHeaderbarConfig = {
                locale: "${LOCALE === 'en' ? 'en_GB' : LOCALE || 'en_GB'}",
                graphqlUri: "${REACT_APP_GRAPHQL_URI}",
                membershipUrl: "${REACT_APP_MEMBERSHIP_URL}",
                siteIndex: "${REACT_APP_SITE_INDEX}",
                cdn: "${REACT_APP_CDN}",
                theme: "light",
                defaultLocale: "en_GB",
                siteTag: "se-survey",
                returnUrl: "${REACT_APP_RETURN_URL}/user/sso?ret=${encodeURIComponent(window.location.href)}",
              }
            `}
          </script>
          <script async="" defer="" src={`${REACT_APP_HEADERBAR}`} />
        </Helmet>
        {show404 && !showNoAccessPage && <Page404 />}
        {showNoAccessPage && <NoAccessPage />}
        {!show404 && !showNoAccessPage && <Suspense fallback={<div className="container-fluid mt-3"> </div>}> <Layout /></Suspense>}
      </>
    </ThemeProvider>
  )
}

export default App
