import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 614px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 46px;
  padding: 16px;
  margin: 120px 0;

  span {
    margin: 46px 0;
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 28px;
  }
`

const ErrorCode = styled.div`
  border-right: 1px solid #f2f2f2;
  padding-right: 16px;
  padding-right: 1rem;
  margin-right: 16px;
  margin-right: 1rem;
`

const Page404 = () => (
  <Container>
    <ErrorCode>404</ErrorCode>
    <span>Page Not Found</span>
  </Container>
)

export default Page404
