import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  assoc, assocPath, compose, path,
} from 'ramda'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useRecoilState, useRecoilValue } from 'recoil'

import progressState from '../../states/progress-state'
import validationState from '../../states/validation-state'
import shakeArray from '../../utils/shakeArray'

import { useWindowSize } from '../../hooks/use-window-size'

import { Columns, Button } from './answer-sort.styles'
import Column from './column'
import PlaceholderColumn from './initial-placeholder-column'

import { AnswerSortMobile } from './answer-sort-mobile'
import surveyDataState, { translationData } from '../../states/survey-data-state'

export const AnswerSort = ({
  item
}) => {
  const { id, options, random, imageUrl, altText } = item
  const [{ answers: { [id]: answers = [] } }, setProgress] = useRecoilState(progressState)
  const { locale } = useRecoilValue(surveyDataState)
  const { data: translations = {} } = useRecoilValue(translationData(locale))
  const [{
    [id]: {
      status: isValid = true,
      isVisible: showErrorMsg = false,
    } = {},
  }] = useRecoilState(validationState)
  const [columns, setColumns] = useState({
    initial: {
      id: 'initial',
      list: [],
    },
    final: {
      id: 'final',
      list: [],
    },
  })

  const size = useWindowSize()

  useEffect(() => {
    if ((options.length && !answers.length) || answers.length !== columns.final.list.length) {
      const final = []
      answers.forEach((answer) => {
        const foundOption = options.find(({ value }) => answer === value)
        if (foundOption) {
          final.push(foundOption)
        }
      })

      let initial = options.filter(({ value }) => !answers.includes(value))

      if (random) {
        initial = shakeArray(initial)
      }

      setColumns(compose(assocPath(['initial', 'list'], initial), assocPath(['final', 'list'], final)))
    }
  }, [options, random]) // eslint-disable-line

  const handleChange = useCallback((newColumns) => {
    setColumns(newColumns)

    const answer = path(['final', 'list'], newColumns).map(({ value }) => value)
    setProgress(assocPath(['answers', id], answer))
  }, [id, setProgress])

  const onDragEnd = useCallback(({ source, destination }) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId
      && destination.index === source.index
    ) return null

    // Set start and end variables
    const start = columns[source.droppableId]
    const end = columns[destination.droppableId]

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_, idx) => idx !== source.index,
      )

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index])

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      }

      // Update the state
      handleChange(assoc(newCol.id, newCol)(columns))
      return null
    }
    // If start is different from end, we need to update multiple columns
    // Filter the start list like before
    const newStartList = start.list.filter(
      (_, idx) => idx !== source.index,
    )

    // Create a new start column
    const newStartCol = {
      id: start.id,
      list: newStartList,
    }

    // Make a new end list array
    const newEndList = end.list

    // Insert the item into the end list
    newEndList.splice(destination.index, 0, start.list[source.index])

    // Create a new end column
    const newEndCol = {
      id: end.id,
      list: newEndList,
    }

    // Update the state
    handleChange(compose(assoc(newStartCol.id, newStartCol), assoc(newEndCol.id, newEndCol))(columns))
    return null
  }, [columns, handleChange])

  const reset = () => {
    setColumns({
      initial: {
        id: 'initial',
        list: options,
      },
      final: {
        id: 'final',
        list: [],
      },
    })
    setProgress(assocPath(['answers', id], []))
  }

  return (
    <div style={{ textAlign: 'left'}}>
      {size.width > 640 &&
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Columns height={options.length}>
              {/* <PlaceholderColumn
                list={options}
              /> */}
              <Column
                list={path(['final', 'list'], columns) || []}
                id={path(['final', 'id'], columns)}
                isValid={!showErrorMsg || isValid}
                final
                imageUrl={imageUrl}
                altText={altText}
                options={options}
              />
              <Column
                list={path(['initial', 'list'], columns) || []}
                id={path(['initial', 'id'], columns)}
                imageUrl={imageUrl}
                altText={altText}
                options={options}
              />
            </Columns>
          </DragDropContext>
          <Button onClick={reset}>
            {translations.button.reset || 'Reset'}
          </Button>
        </>
      }
      {size.width <= 640 && <AnswerSortMobile item={item} />}
    </div>
  )
}

AnswerSort.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    random: PropTypes.bool,
    required: PropTypes.bool,
  }).isRequired,
}
