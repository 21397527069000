import styled from 'styled-components'

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TextArea = styled.textarea`
  width: 100%;
  font-size: 18px;
  resize: none;
  min-height: 196px;
  padding: 20px;
  border-color: ${({ isValid }) => (isValid ? 'black' : 'red')};
  margin-bottom: 5px;
  font-family: sans-serif;
`

export const Info = styled.div`
  width: 100%;
  text-align: left;
  color: ${({ error }) => (error ? 'red' : 'black')};
  margin-bottom: 12px;
  position: relative;

  p {
    font-weight: bold !important;
    padding-right: 32px !important;
    margin-top: 4px !important;
    font-size: 12px;
  }
`

export const CharLimit = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 4px;
`
