import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { assocPath, path } from 'ramda'
import { useRecoilState, useRecoilValue } from 'recoil'
import Image from '../image'

import progressState from '../../states/progress-state'

import {
  RadioContainer,
  Radio,
  LabelContainer,
  Container,
} from '../answer-radio/answer-radio.styles'

import { WithImageContainer } from '../shared/styles.css'

import {
  RangeContainer,
  Thumb,
  Track,
  Slider,
  Title,
  SliderContainer,
  RangeIndicator,
  RangeImageContainer,
  MainContainer,
  Text,
} from './answer-range.styles'
import surveyDataState, { translationData } from '../../states/survey-data-state'

const NOT_KNOW_VALUE = 'not-know-enough'

export const AnswerRange = ({
  item: {
    id,
    range: {
      max,
      min,
      defaultValue,
    },
    notKnow,
    required,
    notKnowText,
    imageUrl,
    altText,
    rowText
  },
  hideImg
}) => {
  const [progress, setProgress] = useRecoilState(progressState)
  const { locale } = useRecoilValue(surveyDataState)
  const { data: translations = {} } = useRecoilValue(translationData(locale))

  const value = path(['answers', id, 'value'], progress)

  console.log('deff', defaultValue)

  // useEffect(() => {
  //   // set progress to default slider value to min
  //   const answer = min
  //   setProgress(assocPath(['answers', id, 'value'], answer))
  // }, [])

  const handleChange = useCallback((newValue) => {
    const answer = newValue === NOT_KNOW_VALUE ? newValue : Number(newValue)
    setProgress(assocPath(['answers', id, 'value'], answer))
  }, [id, setProgress])

  const finalValue = value === NOT_KNOW_VALUE || !value ? defaultValue || min : value

  return (
    <WithImageContainer imageUrl={imageUrl}>
      {imageUrl && !hideImg && <Image src={imageUrl} alt={altText} />}
      <MainContainer>
        <RangeImageContainer>
          <RangeContainer>
            {rowText && <Text>{rowText}</Text>}
            <Title>{translations.ratemsg.replace('{max}', max)}</Title>
            <SliderContainer>
              <Slider
                value={finalValue}
                onChange={handleChange}
                min={min}
                max={max}
                pearling
                ticks
                renderThumb={(props) => <Thumb {...props}/>}
                renderTrack={(props, state) => <Track {...props} index={state.index} />}
              />
              <RangeIndicator>
                <span>{finalValue}</span>
                <span>/{max}</span>
              </RangeIndicator>
            </SliderContainer>
          </RangeContainer>
        </RangeImageContainer>
        {
          notKnow && (
            <Container isValid>
              <label key={NOT_KNOW_VALUE} htmlFor={`q${id}.${NOT_KNOW_VALUE}`}>
                <RadioContainer>
                  <Radio
                    type="radio"
                    id={`q${id}.not-know`}
                    onChange={() => handleChange(NOT_KNOW_VALUE)}
                    checked={value === NOT_KNOW_VALUE}
                    name={`q${id}.answer`}
                    value={NOT_KNOW_VALUE}
                    required={required}
                  />
                </RadioContainer>
                <LabelContainer isValid>
                  {notKnowText}
                </LabelContainer>
              </label>
            </Container>
          )
        }
      </MainContainer>
    </WithImageContainer>
  )
}

AnswerRange.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    range: PropTypes.shape({
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      defaultValue: PropTypes.number,
    }).isRequired,
  }).isRequired,
}

AnswerRange.defaultProps = {
  hideImg: false,
}
