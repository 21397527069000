/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { assocPath, path } from 'ramda'
import { useRecoilState } from 'recoil'
import ReactMarkdown from 'react-markdown'
import Image from '../image'

import progressState from '../../states/progress-state'
import validationState from '../../states/validation-state'
import { useWindowSize } from '../../hooks/use-window-size'

import { AnswerRange } from '../answer-range/answer-range'

import { WithImageContainer } from '../shared/styles.css'

import {
  Container,
  Radio,
  Label,
  Title,
  OptionsContainer,
  Row,
  RanksContainer,
  QuestionContainer,
} from './answer-rank.styles'

import ExclamationIcon from '../shared/exclamation-icon'

const NOT_KNOW_VALUE = 'not-know-enough'

export const AnswerRank = ({
 item
}) => {
  const {
    required,
    id,
    range: { min, max, defaultValue = '' },
    notKnow,
    notKnowText,
    imageUrl,
    altText,
    rowText
  } = item

  const [{
    [id]: {
      radioStatus: isRadioValid = true,
      isVisible: showErrorMsg = false,
    } = {},
  }, setValidation] = useRecoilState(validationState)
  const [progress, setProgress] = useRecoilState(progressState)
  const answer = path(['answers', id, 'value'], progress)
  const size = useWindowSize()

  const finalValue = answer === NOT_KNOW_VALUE || (!answer ? defaultValue || '' : answer)

  const handleChange = useCallback(({ target: { value: newValue } }) => {
    setValidation(assocPath([id, 'isVisible'], false))
    setProgress(assocPath(['answers', id, 'value'], newValue))
  }, [id, setProgress, setValidation])

  const options = useMemo(() => Array.from({ length: max - min + 1 }).map((_, key) => key + parseInt(min, 10)), [min, max])

  const handleMDLinkTarget = () => '_blank'

  const isValid = !showErrorMsg || isRadioValid

  return (
    <WithImageContainer imageUrl={imageUrl} >
      {imageUrl && <Image src={imageUrl} alt={altText} />}
      <Container>
        {
          size.width > 640 &&
          <RanksContainer isValid={isValid}>
            <Row>
                <QuestionContainer white></QuestionContainer>
                <OptionsContainer>
                  <Row>
                    {options.map((value) => <Title key={value}>{value}</Title>)}
                  </Row>
                </OptionsContainer>
            </Row>
            <Row>
              <QuestionContainer>
                {rowText}
                {!isValid && <ExclamationIcon />}
              </QuestionContainer>
              <OptionsContainer>
                <Row>
                  {
                      options.map((value) => (
                        <Label key={value}>
                          <Radio
                            type="radio"
                            id={`q${id}.${value}`}
                            onChange={handleChange}
                            checked={!Number.isNaN(Number(value)) && !Number.isNaN(parseInt(finalValue, 10)) && Number(finalValue) === Number(value) }
                            name={`q${id}.answer`}
                            value={value}
                            required={required}
                            tabIndex="0"
                          />
                        </Label>
                      ))
                  }
                </Row>
                {notKnow && (
                  <Row>
                    <Label htmlFor={`q${id}.not-know`}>
                      <ReactMarkdown source={notKnowText} linkTarget={handleMDLinkTarget} escapeHtml={false} />
                      <Radio
                        type="radio"
                        id={`q${id}.not-know`}
                        onChange={handleChange}
                        checked={answer === NOT_KNOW_VALUE}
                        name={`q${id}.answer`}
                        value={NOT_KNOW_VALUE}
                        required={required}
                        tabIndex="0"
                      />
                    </Label>
                  </Row>
                )}
                </OptionsContainer>
            </Row>
          </RanksContainer>
        }
        {
          size.width <= 640 &&
            <AnswerRange item={item} hideImg/>
        }
      </Container>
    </WithImageContainer>
  )
}

AnswerRank.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    range: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      defaultValue: PropTypes.number,
    }).isRequired,
    random: PropTypes.bool,
    required: PropTypes.bool,
    notKnow: PropTypes.bool,
    notKnowText: PropTypes.string,
  }).isRequired,
}
