import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useRecoilValue } from 'recoil'
import surveyDataState from '../../states/survey-data-state'

import { Container } from './footer.styles'

export const Footer = () => {
  const { contentJson: { text: surveyText } = {} } = useRecoilValue(surveyDataState)
  const legalLines = surveyText['legal'].replace(/\n/g , "<br>");

  return (
    <Container>
      <ReactMarkdown source={legalLines} escapeHtml={false} />
    </Container>
  )
}
