import React from 'react'
import PropTypes from 'prop-types'

import { Img } from './answer-image.styles'

export const AnswerImage = ({ item: { alt, src } }) => (
  <Img src={src} alt={alt} />
)

AnswerImage.propTypes = {
  item: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
}
