import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { assocPath } from 'ramda'
import ReactMarkdown from 'react-markdown'
import { useRecoilState, useRecoilValue } from 'recoil'
import Image from '../image'

import progressState from '../../states/progress-state'
import surveyDataState, { translationData } from '../../states/survey-data-state'
import validationState from '../../states/validation-state'

import { WithImageContainer } from '../shared/styles.css'

import {
  Info,
  TextArea,
  TextContainer,
  CharLimit
} from './answer-text.styles'

const MAX_CHAR_LIMIT = 500

export const AnswerText = ({ item: { required, id, imageUrl, altText } }) => {
  const { locale } = useRecoilValue(surveyDataState)
  const { data: { personalinfo = '' } } = useRecoilValue(translationData(locale))

  const [{
    [id]: {
      status: isValid = true,
      isVisible: showErrorMsg = false,
    } = {},
  }] = useRecoilState(validationState)
  const [progress, setProgress] = useRecoilState(progressState)
  const ref = useRef()
  const handleMDLinkTarget = () => '_blank'
  const { answers: { [id]: value } = {} } = progress
  const [answer, setAnswer] = useState(value)

  const handleBlur = useCallback(({ target: { value: newValue } }) => {
    setProgress(assocPath(['answers', id], newValue))
  }, [setProgress, id])

  const handleChange = useCallback(({ target: { value: newValue } }) => {
    setAnswer(newValue)
  }, [setAnswer])

  const textLength = value && value.length || 0

  return (
    <>
      <WithImageContainer imageUrl={imageUrl}>
        {imageUrl && <Image src={imageUrl} alt={altText} />}
        <TextContainer>
          <TextArea
            ref={ref}
            value={answer}
            onChange={handleChange}
            onBlur={handleBlur}
            required={required}
            isValid={!showErrorMsg || isValid}
            maxLength={MAX_CHAR_LIMIT}
          />
          <Info>
            <CharLimit>{(MAX_CHAR_LIMIT - textLength)}</CharLimit>
            <ReactMarkdown source={personalinfo} linkTarget={handleMDLinkTarget} escapeHtml={false} />
          </Info>
        </TextContainer>
      </WithImageContainer>
    </>
  )
}

AnswerText.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
  }).isRequired,
}
