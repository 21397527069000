import { selector } from 'recoil'
import {
  assoc, assocPath, compose, path,
} from 'ramda'

import progressAtom from './progress-atom'
import surveyDataState from './survey-data-state'
import validationState from './validation-state'

export const permPages = ['intro', 'outro', 'loginRequired', 'closed']

const progressState = selector({
  key: 'progressState',
  get: ({ get }) => get(progressAtom),
  set: ({ get, set }, newProgress) => {
    let surveyData = get(surveyDataState)
    const {
      contentJson: {
        pages = [],
        isSurveyClosed,
        isSiteLoginRequired,
      } = {},
    } = surveyData
    const prevValidation = get(validationState)

    const { answers } = newProgress

    const pagesWithFilteredQuestions = pages.map((page) => {
      const questions = path(['questions'], page) || []
      const filteredQuestions = questions.filter(({ conditions }) => !conditions || conditions.every(({
        question: parentQuestionId,
        answers: conditionAnswers,
        ref,
      }) => {
        const questionPath = [parentQuestionId]
        if (ref) {
          questionPath.push(ref)
        }
        const parentAnswers = path(questionPath, answers) || ''

        if (Array.isArray(parentAnswers)) {
          const isVisible = parentAnswers.some(({ value }) => conditionAnswers.includes(value))
          return isVisible
        }

        return conditionAnswers ? conditionAnswers.includes(String(parentAnswers.value)) : false
      }))
      return assoc('filteredQuestions', filteredQuestions)(page)
    })
    console.log('isSiteLogin', isSiteLoginRequired, get(progressAtom));
    let filteredPages = pagesWithFilteredQuestions.filter(({ filteredQuestions }) => filteredQuestions.length > 0)
    if (!isSurveyClosed) {
      filteredPages = filteredPages.filter((page) => (page.id !== 'closed'))
    }

    surveyData = assocPath(['contentJson', 'filteredPages'], filteredPages)(surveyData)

    let newValidation = {}

    filteredPages.forEach(({ filteredQuestions }) => {
      filteredQuestions.forEach(({
        required, id: questionId, options, type, unique, rows, maxSelections
      }) => {
        let status = !required || !!answers[questionId] || false
        let validation = {
          status,
          isVisible: false,
        }

        if (type === 'sort') {
          status = !required || (answers[questionId] && answers[questionId].length === options.length) || false
          const { status: prevStatus = false } = prevValidation[questionId] || {}

          validation = {
            status,
            isVisible: prevStatus && !status,
          }
        } else if (type === 'text') {
          status = !required || answers[questionId] || false
          const { status: prevStatus = false } = prevValidation[questionId] || {}

          validation = {
            status,
            isVisible: prevStatus && !status,
          }
        } else if (type === 'dropdown') {
          const value = path([questionId, 'value'], answers)
          status = !required || value || false
          const { status: prevStatus = false } = prevValidation[questionId] || {}

          validation = {
            status,
            isVisible: prevStatus && !status,
          }
        } else if (type === 'wordselect') {
          const onlyFilledWords = (answers[questionId] || []).filter((v) => !!v)
          const duplicatesCounter = onlyFilledWords.reduce((acc, curr, i) => {
            acc[curr] = acc[curr] || []
            acc[curr].push(i)

            return acc
          }, {})
          status = !required || onlyFilledWords.length === maxSelections || false
          const { status: prevStatus = false } = prevValidation[questionId] || {}
          const invalidFields = Object.values(duplicatesCounter).reduce((acc, curr) => {
            if (curr.length > 1) {
              return [
                ...acc,
                ...curr,
              ]
            }

            return acc
          }, [])

          validation = {
            status,
            invalidFields,
            isVisible: invalidFields.length > 0 || (prevStatus && !status),
          }
        } else if (type === 'checkbox') {
          const answersArray = answers[questionId]
          const checkboxStatus = !required || (!!answersArray && answersArray.length)
          const textStatus = !answersArray || (answersArray.length && answersArray.every((answer) => options.every(({
            extraTextRequired,
            value,
          }) => value !== answer.value || !extraTextRequired || answer.text.length)))
          status = checkboxStatus && textStatus
          const { checkboxStatus: prevCheckboxStatus } = prevValidation[questionId] || {}

          validation = {
            status,
            checkboxStatus,
            textStatus,
            isVisible: prevCheckboxStatus && !checkboxStatus,
          }
        } else if (type === 'radio') {
          const answerValue = path([questionId, 'value'], answers)
          const radioStatus = !required || !!answerValue
          const textStatus = !answerValue || options.every(({
            extraTextRequired,
            value,
          }) => value !== answers[questionId].value || !extraTextRequired || answers[questionId].text.length)
          status = radioStatus && textStatus
          const { radioStatus: prevRadioStatus } = prevValidation[questionId] || {}

          validation = {
            status,
            radioStatus,
            textStatus,
            isVisible: prevRadioStatus && !radioStatus,
          }
        } else if (type === 'matrix') {
          const prevStatus = Object.values(path(['refs'])(prevValidation) || {}).length > 0 && Object.values(path(['refs'])(prevValidation)).every((answer) => answer)
          validation = {
            refs: {},
          }

          rows.forEach(({ ref }) => {
            const answer = path([questionId, ref])(answers)
            status = false
            if (unique) {
              status = !required || !!answer
            } else {
              status = !required || (!!answer && answer.length > 0)
            }

            validation = assocPath(['refs', ref], status)(validation)
          })
          status = Object.values(path(['refs'])(validation) || {}).every((answerValidation) => answerValidation)

          validation = compose(assocPath(['isVisible'], prevStatus && !status), assocPath(['status'], status))(validation)
        } else if (type === 'rank') {
          const value = path([questionId, 'value'], answers)
          status = !required || !!value || false
          const { status: prevStatus = false } = prevValidation[questionId] || {}

          validation = {
            status,
            isVisible: prevStatus && !status,
          }
        } else if (type === 'markdown') {
          validation = {
            status: true,
            isVisible: false,
          }
        }

        newValidation = assoc(questionId, validation)(newValidation)
      })
    })

    set(surveyDataState, surveyData)
    set(validationState, newValidation)
    set(progressAtom, newProgress)
  },
})

export default progressState
