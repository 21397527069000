import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import progressState from '../../states/progress-state'
import surveyDataState from '../../states/survey-data-state'

import { ProgressContainer, Bar } from './nav.styled'

export const Nav = () => {
  const { progress } = useRecoilValue(progressState)
  const { contentJson: { filteredPages = [] } = {} } = useRecoilValue(surveyDataState)

  const current = useMemo(() => filteredPages.findIndex(({ id }) => id === progress), [filteredPages, progress])
  const total = filteredPages.length - 1
  const percentage = Math.floor((current / total) * 100)

  return (
    <ProgressContainer>
      <Bar value={percentage} />
    </ProgressContainer>
  )
}
