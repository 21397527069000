/* eslint-disable react/no-array-index-key */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  append, assocPath, clone, path, update,
} from 'ramda'
import { useRecoilState, useRecoilValue } from 'recoil'
import Image from '../image'

import progressState from '../../states/progress-state'
import validationState from '../../states/validation-state'

import ExclamationIcon from '../shared/exclamation-icon'

import { WithImageContainer } from '../shared/styles.css'

import {
  Container,
  InputText,
  InputsContainer,
  InputContainer,
} from './answer-wordselect.styles'
import surveyDataState, { translationData } from '../../states/survey-data-state'

export const AnswerWordselect = ({ item: { id, maxSelections, imageUrl, altText } }) => {
  const [{
    [id]: {
      status: isValid = true,
      invalidFields,
      isVisible: showErrorMsg = false,
    } = {},
  }, setValidation] = useRecoilState(validationState)
  const [progress, setProgress] = useRecoilState(progressState)
  const { locale } = useRecoilValue(surveyDataState)
  const { data: translations = {} } = useRecoilValue(translationData(locale))
  const answer = path(['answers', id], progress) || []

  const handleBlur = useCallback((key) => (e) => {
    const { target: { value: newValue } } = e

    setProgress((currentProgress) => {
      let newProgress = clone(currentProgress)
      let answer = (path(['answers', id], newProgress) || [])

      newProgress = assocPath(['answers', id], answer)(newProgress)
      if (key in answer) {
        answer = update(key, newValue)(answer)
      } else {
        answer = append(newValue)(answer)
      }

      return assocPath(['answers', id], Array.from(answer))(newProgress)
    })
  }, [id, setProgress])

  const handleFocus = useCallback(() => {
    setValidation(assocPath([id, 'isVisible'], false))
  }, [setValidation, id])


  return (
    <Container>
      <WithImageContainer imageUrl={imageUrl}>
      {imageUrl && <Image src={imageUrl} alt={altText} />}
        <InputsContainer>
          {Array.from({ length: maxSelections }).map((_, key) => {
            const isWsValid = !showErrorMsg || (isValid && !invalidFields.includes(key))
            const finalAnswer = answer[key] || ''
            const isInvalid = !isWsValid && finalAnswer.length === 0

            return (
              <InputContainer
                hasValue={finalAnswer}
                key={key}
                isInvalid={isInvalid}
              >
                  <label>
                    {isInvalid && <ExclamationIcon />}
                    {translations.entertextmsg || 'Please enter text'}
                  </label>
                <InputText
                  type="text"
                  onBlur={handleBlur(key)}
                  onFocus={handleFocus}
                  defaultValue={finalAnswer}
                />
              </InputContainer>
            )
          })}
        </InputsContainer>
      </WithImageContainer>
    </Container>
  )
}

AnswerWordselect.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    maxSelections: PropTypes.number.isRequired,
  }).isRequired,
}
