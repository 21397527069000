/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  append, assocPath, clone, path, remove,
} from 'ramda'
import { useRecoilState } from 'recoil'
import Image from '../image'

import { WithImageContainer } from '../shared/styles.css'

import progressState from '../../states/progress-state'
import validationState from '../../states/validation-state'
import { useWindowSize } from '../../hooks/use-window-size'
import shakeArray from '../../utils/shakeArray'

import {
  Container,
  Cell,
  Option,
  MainContainer,
  Row,
} from './answer-matrix.styles'
import ExclamationIcon from '../shared/exclamation-icon'

import TickIcon from '../shared/tick-icon'

import { AnswerMatrixMobile, MobileRow } from './answer-matrix-mobile'

export const AnswerMatrix = ({
  item
}) => {
  const {
    required,
    id,
    rows,
    random,
    columns,
    unique,
    maxSelections,
    disabled,
    imageUrl,
    altText
  } = item

  const [validationObj, setValidation] = useRecoilState(validationState)
  const validation = path([id, 'refs'], validationObj) || {}
  const showErrorMsg = path([id, 'isVisible'], validationObj) || false
  const [progress, setProgress] = useRecoilState(progressState)
  const answer = path(['answers', id], progress) || {}
  const size = useWindowSize()

  const handleChange = useCallback((ref) => ({ target: { value: newValue } }) => {
    setValidation(assocPath([id, 'isVisible'], false))
    if (unique) {
      setProgress(assocPath(['answers', id, ref, 'value'], newValue))
    } else {
      setProgress((currentProgress) => {
        const selections = path(['answers', id, ref], currentProgress) || []

        let newSelections = clone(selections)
        if (!Array.isArray(newSelections)) {
          newSelections = []
        }
        const existingIndex = newSelections.findIndex((selectedOption) => selectedOption.value === newValue)

        if (existingIndex > -1) {
          newSelections = remove(existingIndex, 1, newSelections)
        } else {
          newSelections = append({ value: newValue }, newSelections)
        }

        return assocPath(['answers', id, ref], newSelections.length > 0 ? newSelections : undefined)(currentProgress)
      })
    }
  }, [id, unique, setProgress, setValidation])

  const sortedRows = useMemo(() => {
    if (random) {
      return shakeArray(rows)
    }
    return rows
  }, [rows, random])

  return (
    <WithImageContainer imageUrl={imageUrl} >
      {imageUrl && <Image src={imageUrl} alt={altText} />}
      <MainContainer>
        {
          size.width > 640 &&
            <>
              <Container>
                {
                  !imageUrl &&
                    <Row nOfColumns={columns.length + 1}>
                      <Cell title/>
                      {columns.map(({ label, value }) => (<Cell title align="center" key={value}>{label}</Cell>))}
                    </Row>
                }
                {sortedRows.map(({ text, ref }) => {
                  const isRowValid = !showErrorMsg || validation[ref]

                  if (imageUrl) {
                    return <MobileRow
                      key={ref}
                      ref_={ref}
                      isRowValid={isRowValid}
                      text={text}
                      columns={columns}
                      answer={answer}
                      required={required}
                      unique={unique}
                      maxSelections={maxSelections}
                      id={id}
                      disabled={disabled}
                      handleChange={handleChange}
                    />
                  }
                  return (
                    <Row
                      key={ref}
                      nOfColumns={columns.length + 1}
                      isInvalid={!isRowValid}
                    >
                      <Cell question>
                        {!isRowValid && <ExclamationIcon />}
                        {text}
                      </Cell>
                      {columns.map(({ value, id: columnId }) => {
                        let isChecked = false
                        let isDisabled = (path(['ref'], disabled) || []).includes(columnId)

                        if (unique) {
                          const answerValue = path([ref, 'value'], answer)
                          isChecked = answerValue === value
                        } else {
                          const answerValue = path([ref], answer) || []
                          isChecked = answerValue.some((selectedOption) => selectedOption.value === value)
                          isDisabled = isDisabled || (maxSelections && !isChecked && answerValue.length === maxSelections)
                        }

                        return (
                          <Cell
                            align="center"
                            key={value}
                          >
                            <label
                              htmlFor={`q${id}.r${ref}.${value}`}
                            >
                              <Option
                                type={unique ? 'radio' : 'checkbox'}
                                id={`q${id}.r${ref}.${value}`}
                                onChange={handleChange(ref)}
                                checked={isChecked}
                                name={`q${id}.r${ref}.answer`}
                                value={value}
                                required={required}
                                disabled={isDisabled}
                                tabIndex="0"
                              />
                              {!unique && isChecked && <TickIcon />}
                            </label>
                          </Cell>
                        )
                      })}
                    </Row>
                  )
                })}
              </Container>
            </>
          }
          {
            size.width <= 640 &&
              <AnswerMatrixMobile item={item} />
          }
      </MainContainer>
    </WithImageContainer>
  )
}

AnswerMatrix.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      id: PropTypes.string,
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
      ref: PropTypes.string,
      text: PropTypes.string,
    })).isRequired,
    disabled: PropTypes.shape({
      [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
    }),
    random: PropTypes.bool,
    required: PropTypes.bool,
    unique: PropTypes.bool,
    maxSelections: PropTypes.number,
  }).isRequired,
}
