import styled, { css } from 'styled-components'

const TRANSITION_TIME = '0.3s'

export const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 40px);
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;

  & > * {
    width: 100%;
  }

  & .item {
    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity ${TRANSITION_TIME} ease-in;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity ${TRANSITION_TIME} ease-in;
      }
    }
  }
`

export const Questions = styled.div`
  padding: 16px;
  max-width: 1220px;
  flex: 1;
  padding-bottom: 300px;

  ${({ isIntroOrOutro }) => isIntroOrOutro && css`
    text-align: center;
    padding: 32px;

    p  {
      text-align: center;
    }

    @media (max-width: 640px) {
      padding: 16px;
    ]
  `}

  @media (max-width: 640px) {
    padding: 0 16px;
    padding-bottom: 350px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin 0 10px;
  }
`

export const Header = styled.header`
  background-color: rgb(13, 13, 13);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;

  @media (min-width: 640px) {
    height: 160px;
  }
`

export const Logo = styled.img`
  width: 330px;
  max-width: 70%;
  height: 35px;
  pointer-events: none;
`

export const ErrorMsg = styled.div`
  color: red;
  text-align: left;
  padding: 16px 0;
  font-weight: bold;
`

export const FooterContainer = styled.div`
  margin-top: 32px;
  position: fixed;
  bottom:  0;
  z-index: 99999;

  ${({ isIntro }) => isIntro && css`
    position: relative;
  `}
`
