import styled, { css }  from 'styled-components'

export const MarkdownContainer = styled.div`
  text-align: left;
  ${({ loginRequired }) => loginRequired && css`text-align: center;`}

  a {
    color: black;

    &:hover {
      color: #666;
    }
  }

  ul, ol {
    padding: 0 16px;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #333;
    margin: 1.5em 10px;
    padding: 25px 10px;

    &:before {
      color: #333;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }

    p {
      display: inline;
      font-weight: bold;
      color: #333;
    }
  }
`
