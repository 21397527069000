import styled from 'styled-components'

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;
  padding: 0 32px;

  @media (max-width: 640px) {
    padding: 0 4px;
  }
`

export const Percentage = styled.div`
  font-size: 14px;
  margin-right: 20px;
`

export const Bar = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #cccccc;

  &:after {
    content: '';
    display: block;
    width: ${({ value }) => value}%;
    background-color: #4d4d4d;
    height: 100%;
    transition: 0.6s width ease;
  }

  @media (max-width: 640px) {
    height: 16px;
  }
`
