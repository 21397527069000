import styled, { css } from 'styled-components'
import ReactSlider from 'react-slider'

export const RangeImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

export const RangeContainer = styled.div`
  font-weight: bold;
  background: #eee;
  padding: 16px;
  text-align: left;
  height: min-content;
  width: 100%;
`

export const Title = styled.div`
  margin-bottom: 16px;
`

export const Slider = styled(ReactSlider)`
  width: 100%;
  height: 30px;
  margin: 16px 0;
`

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
`

export const RangeIndicator = styled.div`
  background: #ddd;
  height: 42px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  height: 75px;
  width: 75px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  position: relative;

  &:before {
    position: absolute;
    content: ' ';
    left: -8px;
    width: 0;
    height: 0;
    border-width: 9.5px 14px 9.5px 0;
    border-color: transparent #DDDDDD transparent transparent;
    border-style: solid;
  }

  span:last-child {
    font-weight: normal;
  }
`

export const Thumb = styled.div`
  line-height: 24px;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  border: 5px solid white;
  box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.2);
  border-radius: 50%;
  background-color: #333;
  color: #000;
  text-align: center;
  position: absolute;
  top: -2px;
  cursor: grab;
`

export const Track = styled.div`
  top: 0;
  bottom: 0;
  background: white;
  box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.06);
  border-radius: 999px;
  height: 25px;
`

export const NotKnow = styled.div`
  background-color: #333;
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #ddd;
  height: 60px;
  width: 60px;
`

const Option = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  flex: 0 0 18px;
  outline: none;
  border: 1px solid #999;
  background: white;
`

export const Tick = styled.div`
  display: inline-block;
  position: absolute;
  transform: rotate(45deg);
  height: 14px;
  width: 7px;
  border-bottom: 4px solid #999;
  border-right: 4px solid #999;
`

export const Checkbox = styled(Option)`

  ${({ disabled }) => disabled && css`
    border-color: #ccc;
  `}
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Text = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  font-weight: normal;
`
