/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { append, assocPath, clone, path, remove } from "ramda";
import { useRecoilState } from "recoil";
import Image from "../image";

import progressState from "../../states/progress-state";
import validationState from "../../states/validation-state";
import shakeArray from "../../utils/shakeArray";

import TickIcon from '../shared/tick-icon';

import {
  Container,
  Cell,
  Option,
  MainContainer,
  Row,
  OptionContainer,
  Text,
} from "./answer-matrix.styles";
import ExclamationIcon from "../shared/exclamation-icon";

export const AnswerMatrixMobile = ({
  item: {
    required,
    id,
    rows,
    random,
    columns,
    unique,
    maxSelections,
    disabled,
    imageUrl,
  },
}) => {
  const [validationObj, setValidation] = useRecoilState(validationState);
  const validation = path([id, "refs"], validationObj) || {};
  const showErrorMsg = path([id, "isVisible"], validationObj) || false;
  const [progress, setProgress] = useRecoilState(progressState);
  const answer = path(["answers", id], progress) || {};

  const handleChange = useCallback(
    (ref) => ({ target: { value: newValue } }) => {
      setValidation(assocPath([id, "isVisible"], false));
      if (unique) {
        setProgress(assocPath(["answers", id, ref, "value"], newValue));
      } else {
        setProgress((currentProgress) => {
          const selections = path(["answers", id, ref], currentProgress) || [];

          let newSelections = clone(selections);
          if (!Array.isArray(newSelections)) {
            newSelections = [];
          }
          const existingIndex = newSelections.findIndex(
            (selectedOption) => selectedOption.value === newValue
          );

          if (existingIndex > -1) {
            newSelections = remove(existingIndex, 1, newSelections);
          } else {
            newSelections = append({ value: newValue }, newSelections);
          }

          return assocPath(
            ["answers", id, ref],
            newSelections.length > 0 ? newSelections : undefined
          )(currentProgress);
        });
      }
    },
    [id, unique, setProgress, setValidation]
  );

  const sortedRows = useMemo(() => {
    if (random) {
      return shakeArray(rows);
    }
    return rows;
  }, [rows, random]);

  return (
    <MainContainer>
      <Container>
        {sortedRows.map(({ text, ref }) => {
          const isRowValid = !showErrorMsg || validation[ref];

          return <MobileRow
            key={ref}
            ref_={ref}
            isRowValid={isRowValid}
            text={text}
            columns={columns}
            answer={answer}
            required={required}
            unique={unique}
            maxSelections={maxSelections}
            id={id}
            disabled={disabled}
            handleChange={handleChange}
          />
        })}
      </Container>
    </MainContainer>
  );
};

export const MobileRow = ({
  ref_,
  isRowValid,
  text,
  columns,
  answer,
  required,
  unique,
  maxSelections,
  id,
  disabled,
  handleChange
}) => (
    <Row
      isInvalid={!isRowValid}
      key={ref_}
    >
      <Text>
        {!isRowValid && <ExclamationIcon />}
        {text}
      </Text>
      <div>
        {columns.map(({ value, id: columnId, label }) => {
          let isChecked = false;
          let isDisabled = (path(["ref"], disabled) || []).includes(
            columnId
          );

          if (unique) {
            const answerValue = path([ref_, "value"], answer);
            isChecked = answerValue === value;
          } else {
            const answerValue = path([ref_], answer) || [];
            isChecked = answerValue.some(
              (selectedOption) => selectedOption.value === value
            );
            isDisabled =
              isDisabled ||
              (maxSelections &&
                !isChecked &&
                answerValue.length === maxSelections);
          }

          return (
            <Cell align="center" key={value} mobileStyles>
              <label htmlFor={`q${id}.r${ref_}.${value}`}>
                <OptionContainer>
                  <Option
                    type={unique ? "radio" : "checkbox"}
                    id={`q${id}.r${ref_}.${value}`}
                    onChange={handleChange(ref_)}
                    checked={isChecked}
                    name={`q${id}.r${ref_}.answer`}
                    value={value}
                    required={required}
                    disabled={isDisabled}
                  />
                  {!unique && isChecked && <TickIcon />}
                </OptionContainer>
                <span>
                  {label}
                </span>
              </label>
            </Cell>
          );
        })}
      </div>
    </Row>
)

AnswerMatrixMobile.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        id: PropTypes.string,
      })
    ).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        ref: PropTypes.string,
        text: PropTypes.string,
      })
    ).isRequired,
    disabled: PropTypes.shape({
      [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
    }),
    random: PropTypes.bool,
    required: PropTypes.bool,
    unique: PropTypes.bool,
    maxSelections: PropTypes.number,
  }).isRequired,
};
