/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'

import Item from './item'
import { StyledColumn, StyledList } from './answer-sort.styles'
import PlaceholderColumn from './initial-placeholder-column'

const Column = ({
  list,
  id,
  isValid,
  final,
  clickable,
  move,
  imageUrl,
  altText,
  options,
}) => (
  <Droppable droppableId={id}>
    {({ droppableProps, innerRef, placeholder }, pops, rest) => {
      const isInList = list.find(l => l.label === pops.draggingFromThisWith)

      return (
        <div>
          <StyledColumn>
            <StyledList
              {...droppableProps}
              ref={innerRef}
              isValid={isValid}
            >
              {list.map(({ label, value }, index) => (
                  <Item
                    key={value}
                    text={label}
                    index={index}
                    final={final}
                    clickable={clickable}
                    move={move}
                    length={list.length}
                    imageUrl={imageUrl}
                    altText={altText}
                  />
              ))}
              {placeholder}
            </StyledList>
          </StyledColumn>
          {
            options &&
              <PlaceholderColumn
                list={options}
                final={final}
                highlight={(!isInList && pops.isDraggingOver) ? list.length : undefined }
              />
          }
        </div>
      )
    }}
  </Droppable>
)

Column.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  id: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  final: PropTypes.bool,
}

Column.defaultProps = {
  isValid: true,
  final: false,
}

export default Column
