import styled, { css } from 'styled-components'

const selected = `
  label {
    top: 5px;
    font-size: 12px;
    z-index: 1;
  }

  input {
    z-index: -2;
    opacity: 1;
  }
`

const empty = `
  label {
    font-size: 10px;
    color: grey;
    padding-left: 4px;
    top: 25px;
    position: relative;
    font-size: 16px;
    transition: font-size 0.5s, top 0.5s;
    z-index: -1;
    display: flex;
  }

  input {
    opacity: 0;
  }
`

export const InputContainer = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 18px;

  ${empty}

  &:focus-within {
    ${selected}
  }

  ${({ hasValue }) => hasValue && css`
    ${selected}
  `}

  ${({ isInvalid }) => isInvalid && css`
    border-bottom: 1px solid red;
  `}
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%
`

export const Container = styled.div`
  font-weight: bold;
  margin-top: 20px;
  text-align: left;
`

export const InputText = styled.input`
  appearance: none;
  width: 100%;
  height: 40px;
  font-size: 18px;
  border: none;
  outline: none;
  padding-left: 4px;
`
