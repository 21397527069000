import styled, { css } from 'styled-components'
import { accessibilityOutline } from '../shared/styles.css'

export const MainContainer = styled.div`
  img {
    width: 100%;
  }
`

export const Container = styled.div`
  img {
    width: 100%;
  }
`

export const OptionContainer = styled.div`
  background: #e6e6e6;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`

const mobileStylesOption = `
  margin: 2px 0;

  label {
    display: flex;
    padding: 0;
    align-items: center;
    background: #f4f4f4;
    justify-content: start;
  }

  span {
    padding: 16px;
    padding-left: 0;
    color: grey;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
`

export const Option = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border: 1px solid #999;
  background: white;
  cursor: pointer;
  margin: 0;
  outline: none;

  ${accessibilityOutline}

  ${({ type }) => type === 'radio' && css`
    border-radius: 14px;

    &:checked {
      background-color: #707b76;
      border: 2px solid white;
    }
  `}

  ${({ disabled }) => disabled && css`
    border-color: #ddd;
    background: #ddd;
  `}
`

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    background: #e6e6e6;
    padding: 16px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    cursor:  pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    height: 12px;
    width: 12px;
    position: absolute;
    fill: grey;
  }

  ${({ align }) => !!align && css`
    text-align: ${align};
  `}

  ${({ title }) => !!title && css`
    background: white;
    padding: 0 16px;
    font-weight: bold;
    font-size: 14px;
    align-items: flex-end;
    color: #666;
  `}

  ${({ question }) => question && css`
    background: #f4f4f4;
    justify-content: start;
    align-items: center;
    text-align: left;
    padding: 16px;

    svg {
      height: 18px;
      width: 18px;
    }
  `}

  ${({ isInvalid }) => isInvalid && css`
    border: 1px solid red;
  `}

  ${({ mobileStyles }) => mobileStyles && css`
    ${mobileStylesOption}
  `}

    @media (max-width: 640px) {
      ${mobileStylesOption}
    }
`

export const Row = styled.div`
  display: grid;
  margin-bottom: 16px;

  @media (max-width: 640px) {
    margin: 16px 0;
  }


  ${({ nOfColumns }) => css`
    grid-template-columns: 2fr repeat(${nOfColumns - 1}, 1fr);
  `}

  grid-column-gap: 2px;

  ${({ isInvalid }) => isInvalid && css`
    border: 1px solid red;
  `}
`

export const Text = styled.div`
  background: #f4f4f4;
  padding: 16px;
  text-align: left;
  display: flex;
  align-items: center
`
